import { mcn } from "@/utils/mergeClassNames";
import Button from "@/components/Button";
import { Section, SectionContainer, SectionProps } from "@/components/Sections";
import { TooltipContainer, TooltipContentWrapper } from "@/components/Tooltip";

type Props = SectionProps & {
  title: string;
  actionText: string;
  actionHref: string;
};
export const StartPageEurpoeMapSection = ({
  title,
  actionHref,
  actionText,
  ...sectionProps
}: Props) => {
  return (
    <Section {...sectionProps}>
      <div
        className={mcn(
          "w-full tablet-lg:h-full",
          "min-h-[75vw] tablet-lg:min-h-0",
          "relative tablet-lg:absolute top-0 right-0",
          "bg-no-repeat bg-right-top",
          "bg-[length:auto_100%]",
          "tablet-lg:bg-[length:45%_auto]"
        )}
        style={{
          backgroundImage: `url('images/europe-map-v2.svg')`,
        }}
      />

      <SectionContainer
        grid
        className="tablet-lg:min-h-[50vw] h-full max-tablet:pt-xl"
      >
        <div
          className={mcn(
            "col-span-full tablet-lg:col-span-6",
            "tablet-lg:flex tablet-lg:flex-col tablet-lg:h-full tablet-lg:justify-center"
          )}
        >
          <span className={mcn("text-style-headline4 text-light-yellow")}>
            {title}
          </span>
          <div className="mt-lg z-10">
            <Button href={actionHref}>{actionText}</Button>
          </div>
        </div>
      </SectionContainer>
    </Section>
  );
};
