import { useBaseContext } from "@/components/BaseContext";
import {
  CarouselSection,
  LearningOpportunitySection,
  MotivationScanSection,
} from "@/components/Sections";
import {
  MemberUniversityListSection,
  StartPageECIUorgLinkSection,
  StartPageEurpoeMapSection,
  StartPageInfoSection,
  StartPageTopicSection,
} from "@/components/Sections/StartPageSections";
import { getStartpagePromotedLearningOpportunityList } from "@/server/backend-api";
import { InferGetStaticPropsType } from "next";
import { NextPageWithLayout } from "types";
import { StartPageStaticData } from "_staticContent/start";
import config from "../config";
import { useClientSideCookies } from "@/utils/cookies/cookies";

const staticData = StartPageStaticData;

export const getStaticProps = async () => {
  const startPageLearningOpps =
    await getStartpagePromotedLearningOpportunityList();

  return {
    props: {
      startPageLearningOpps,
    },
    revalidate: config.staticRevalidationTime,
  } as const;
};

type StaticProps = InferGetStaticPropsType<typeof getStaticProps>;

const Home: NextPageWithLayout<StaticProps> = (props) => {
  const baseContext = useBaseContext();
  const secondSectionId = "startpage-second-section";

  const { isCookiesAvailable, getMotivationScanAnswers } =
    useClientSideCookies();
  const answers = isCookiesAvailable ? getMotivationScanAnswers() : null;
  return (
    <>
      <CarouselSection
        {...staticData.carouselSection}
        nextSectionId={secondSectionId}
      />
      <StartPageInfoSection
        id={secondSectionId}
        adjustScrollMarginToStickyObjectHeaderLinks={false}
        color="blue"
        {...staticData.infoSection}
      />
      <MotivationScanSection
        color={"light-green"}
        hasMotivationScanResult={answers !== null}
        isSignedIn={false}
      />
      <LearningOpportunitySection
        color="yellow-polygon"
        heading={staticData.loListStartPageRecommendationsSection.heading}
        description={
          staticData.loListStartPageRecommendationsSection.description
        }
        data={[
          {
            learningOpps: props.startPageLearningOpps,
            ...staticData.loListStartPageRecommendationsSection.container,
          },
        ]}
      />
      <StartPageTopicSection competenceTree={baseContext.competenceTree} />
      <StartPageEurpoeMapSection
        color="light-blue"
        {...staticData.europeMapSection}
      />
      <StartPageECIUorgLinkSection
        color="blue"
        {...staticData.learnMoreSection}
      />
      <MemberUniversityListSection
        memberUniversities={baseContext.memberUniversities}
        color="light-gray"
      />
    </>
  );
};

Home.layout = {
  transparentHeader: true,
};

export default Home;
