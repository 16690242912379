import { Section, SectionContainer, SectionProps } from "@/components/Sections";
import React from "react";
import { OrganisationLinkButton } from "../../OrganisationButton";
import { OrganisationBase } from "@/server/backend-api/organisation";

type Props = SectionProps & {
  memberUniversities: OrganisationBase[];
};

export const MemberUniversityListSection = ({
  memberUniversities,
  ...sectionProps
}: Props) => {
  return (
    <Section {...sectionProps}>
      <SectionContainer>
        <h2 className="text-style-headline2 text-center mb-xl tablet:mb-2xl">
          ECIU University members
        </h2>
        <div className="grid grid-cols-12 gap-default">
          {memberUniversities.map((memberUni) => (
            <OrganisationLinkButton
              key={memberUni.id}
              type="organisation"
              className="col-span-full tablet-sm:col-span-6 tablet:col-span-4 tablet-lg:col-span-3 desktop:col-span-2"
              university={memberUni}
            />
          ))}
        </div>
      </SectionContainer>
    </Section>
  );
};
