import config from "../config";

import startPageCarousel1 from "../public/images/startpage-carousel-1.png";
import startPageCarousel2 from "../public/images/startpage-carousel-2.png";
import startPageCarousel3 from "../public/images/startpage-carousel-3.png";
import { StaticImageData } from "next/image";

export interface CarouselImage {
  src: StaticImageData;
  quality?: number;
}

export const StartPageStaticData = {
  carouselSection: {
    images: [
      {
        src: startPageCarousel1,
      },
      {
        src: startPageCarousel2,
      },
      {
        src: startPageCarousel3,
      },
    ] satisfies CarouselImage[],
    title: "Change the way you learn and create real impact",
    text: "13 European universities are working together to reinvent the way you learn. New and current students can sign up now.",
    actionText: "Join now",
    actionHref: config.auth.signUpUri,
  },

  infoSection: {
    title: "Opportunities to enhance your education",
    paragraphs: [
      {
        heading: "Gain international experience",
        text: "Get an opportunity to study at different European universities the way you want. Go on exchange for a semester, meet up for a few days or participate online.",
      },
      {
        heading: "Earn ECIU University micro-credentials",
        text: "Receive, store, and showcase tamper-proof ECIU University micro-credentials, issued in accordance with European standards and e-sealed by ECIU. Confirm your achievements – and support your career aspirations.",
      },
      {
        heading: "Collaborate with actual companies and organisations",
        text: "Work together and grow your network of connections in the Private, Public or NPO sector.",
      },
      {
        heading: "Make a real impact",
        text: "Gain first-hand experience and a possibility to contribute to real societal change.",
      },
    ],
    actionText: "Learn more",
    actionHref: "/about-why-how",
  },

  loListStartPageRecommendationsSection: {
    heading: "Learning opportunities",
    description: `ECIU offers two different learning options

Challenges are courses that engage diverse teams to solve real-life problems using a novel and practical method of challenge-based learning (CBL).

Micro-modules support learners and help them fill the knowledge gaps, for example, widen their knowledge on strategic planning or sustainable development.`,
    container: {
      actionText: "Discover more",
      actionHref: "/browse",
    },
  },

  topicSection: {
    title: "Solve real-life challenges with ECIU",
    description: `
    Gain first-hand experience and a possibility to contribute to real societal change. The ECIU University challenges are the ones that communities and organisations face at this very moment. Participation in challenges gives a “first hand” experience and a possibility to contribute to real societal change: the community, city or region

    ECIU University challenges evolve around the United Nations Sustainable Development Goal no. 11 “make cities and human settlements inclusive, safe, resilient and sustainable”.
        
    At ECIU we have divided SDG11 into 4 topics: Circular economy, Energy and sustainability, Resilient communities and Transport and mobility
`,
  },

  europeMapSection: {
    title:
      "Join 13 universities, more than 800 students and over 70 companies and organisations in their pursuit of changing the world.",
    actionText: "Join us now",
    actionHref: config.auth.signUpUri,
  },

  learnMoreSection: {
    title: "The European Consortium Of Innovative Universities",
    actionText: "Learn more about ECIU",
    actionHref: "https://www.eciu.org/",
  },
};
