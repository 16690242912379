import IconExternal from "@/components/Icons/IconExternal";
import Link from "next/link";
import { Section, SectionContainer, SectionProps } from "../Sections";
import Image from "next/image";
import eciuLogoFullText from "../../../public/images/eciu-logo-full-text.png";

type Props = SectionProps & {
  title: string;
  actionText: string;
  actionHref: string;
};
export const StartPageECIUorgLinkSection = ({
  title,
  actionHref,
  actionText,
  ...sectionProps
}: Props) => {
  return (
    <Section {...sectionProps}>
      <SectionContainer grid gridNoYGap>
        <div className="col-span-8 col-start-3 ">
          <Image src={eciuLogoFullText} alt={title} />
        </div>

        <div className="col-span-full flex justify-center tablet:justify-end pt-lg">
          <Link
            href={actionHref}
            className="flex items-center"
            target="_blank"
            rel="noreferrer"
          >
            <span className="link tablet:text-style-headline3 text-style-subtitle1 underline-offset-8">
              {actionText}
            </span>
            <IconExternal className="ml-sm text-[24px] tablet:text-[32px]" />
          </Link>
        </div>
      </SectionContainer>
    </Section>
  );
};
