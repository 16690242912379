import { Section, SectionContainer, SectionProps } from "@/components/Sections";
import Button from "@/components/Button";
import { EciuPolygonIcon } from "@/components/EciuPolygons/EciuPolygon";
import { getColorCode } from "@/utils/colors";

type Props = SectionProps & {
  title: string;
  actionText: string;
  actionHref: string;
  paragraphs: {
    heading: string;
    text: string;
  }[];
};
export const StartPageInfoSection = ({
  title,
  paragraphs,
  actionHref,
  actionText,
  ...sectionProps
}: Props) => {
  return (
    <Section {...sectionProps}>
      <SectionContainer
        grid
        className="text-light-yellow gap-y-lg"
        gridNoYGap
      >
        <div className=" col-span-full">
          <h2 className="text-style-headline4 tablet:text-style-headline2 ">
            {title}
          </h2>
        </div>
        <div className="col-span-full row-span-2 grid tablet:grid-cols-2 gap-x-lg gap-y-lg">
          {paragraphs.map((item, index) => (
            <div className="p-4 border border-solid rounded border-light-yellow grid grid-cols-12" key={index}>
              <div className="col-span-1 py-1">
                <EciuPolygonIcon sizeClassName="max-w-[40px]" fillColor={getColorCode("yellow")} />
              </div>
              <div className=" col-span-full col-start-2 px-2">
                <h3 className="text-style-headline5 ">{item.heading}</h3>
                <p className=" text-style-body1 mb-md">{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-full flex justify-center">
          <Button href={actionHref}>{actionText}</Button>
        </div>
      </SectionContainer>
    </Section>
  );
};
